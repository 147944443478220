import * as React from "react";
import { ServiceContents, Container, Breadcrumb } from "../../components";
import Seo from "../../components/Seo";

const Services = () => {
  return (
    <Container>
      <Seo
        title="TKCの提供価値"
        description="TKCホールディングの事業紹介ページです。HP制作やアプリ開発のデザインからコーディングまで、ワンストップで提供する「Web開発事業」と、未経験から市場価値の高いエンジニアを輩出するBiblioTechという「プログラミングスクール事業」を展開しています。"
      />
      <ServiceContents />
      <Breadcrumb currentPage="Services" currentSlug="/services" />
    </Container>
  );
};

export default Services;
